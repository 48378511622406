<template>
    <generic-section-hero
        v-bind="$attrs"
        class="SectionComplexHero"
        :showDragHint="true"
    >
        <template v-slot="{ data, slide, index, state, playVideo }">
            <div class="SectionComplexHero-ContentWrapper">
                <h1>
                    <template v-if="slide.content.title !== 'Be Roma' && slide.content.title !== 'Ocean Nuevo Nayarit'">
                        <div class="SectionComplexHero-Subtitle" :style="`color:${slide.content.colorname};opacity: 1 !important;`" >Be Grand</div>
                        <div class="SectionComplexHero-Title" :data-color="slide.content.colorname">{{ slide.content.title }}</div>
                    </template>
                    <template v-else-if="slide.content.title === 'Ocean Nuevo Nayarit'">
                        <div class="SectionComplexHero-Subtitle" :style="`color:${slide.content.colorname};opacity: 1 !important;`" >Be Grand Ocean</div>
                        <div class="SectionComplexHero-Title" :data-color="slide.content.colorname">NUEVO NAYARIT</div>
                    </template>
                    <template v-else>
                        <div class="SectionComplexHero-Subtitle" :style="`color:${slide.content.colorname};opacity: 1 !important;`" >Be</div>                        
                        <div class="SectionComplexHero-Title" :data-color="slide.content.colorname">Roma</div>
                    </template>                    
                </h1>
            </div>
        </template>
    </generic-section-hero>
</template>

<script>
import GenericSectionHero from '@/app/components/sections/GenericSectionHero.vue'

export default {
    name: 'SectionComplexHero',
    components: {
        GenericSectionHero,
    },
}
</script>

<style src="styles/components/sections/SectionComplexHero.styl" lang="stylus"></style>
